import { useGetLogoutsQuery } from "../../echron/clientapi"
import { useSelector } from 'react-redux'
import { selectAdmLog } from "../../echron/formSlice"
function Logout() {
  const admLog = useSelector(selectAdmLog)
  const logsRes = useGetLogoutsQuery(admLog)
  if(logsRes.isLoading) return <div>Loading...</div>
	if(logsRes.isError) return window.location.href = '/adm/login'
	if(logsRes.isSuccess) return window.location.href = '/adm/login'
}
export default Logout