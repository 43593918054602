import { useState, useEffect } from "react";
import '../../assets/css/CustomerAdd.css'
import Breadcrumb from "../../component/common/Breadcrumb"
import { useDispatch, useSelector } from 'react-redux'
import { echronEvInput, useSubmitForm, selectFormResp, selectForm } from "../../echron/formSlice"
import { useGetIdCustsQuery } from '../../echron/clientapi'
import { useLocation } from 'react-router-dom';
import Warning from "../../component/Warning";


function CustomerAdd() {
  const dispatch = useDispatch();
  const getId = new URLSearchParams(useLocation().search).get('id');

  //get api
  const custsRes = useGetIdCustsQuery(getId);

  //edit case
  useEffect(() => {
    if (custsRes.isSuccess) {
      //const custShow = custsRes.data.result.filter(post => post.id == getId);
      const custShow = custsRes.data.result
      if (custShow.length > 0) {
        dispatch(echronEvInput({ value: custShow[0].name, type: 'name' }));
        dispatch(echronEvInput({ value: custShow[0].email, type: 'email' }));
        dispatch(echronEvInput({ value: custShow[0].phone, type: 'phone' }));
        dispatch(echronEvInput({ value: custShow[0].type, type: 'type' }));
        dispatch(echronEvInput({ value: custShow[0].phone_alt, type: 'phone_alt' }));
        dispatch(echronEvInput({ value: custShow[0].address, type: 'address' }));
      }
      else {
        dispatch(echronEvInput({ value: '', type: 'name' }));
        dispatch(echronEvInput({ value: '', type: 'email' }));
        dispatch(echronEvInput({ value: '', type: 'phone' }));
        dispatch(echronEvInput({ value: '1', type: 'type' }));
        dispatch(echronEvInput({ value: '', type: 'phone_alt' }));
        dispatch(echronEvInput({ value: '', type: 'address' }));
      }
    }
  }, [custsRes, dispatch]);

  
  
  const formSet = useSelector(selectForm)

  //post api
  const submitForm = useSubmitForm(getId);
  const formResps = useSelector(selectFormResp);
  const echronEvSubmit = () => {
    if(!formResps.load) {
      submitForm();
    }
  }

  const breadBtn = <>
    <button className="echron-btn-icon" onClick={()=>echronEvSubmit()} title="Save">
      {formResps.load ? 'Please Wait...' : <i className="fa-solid fa-save"></i>}
    </button>
  </>

  const [isActive, setIsActive] = useState('tab-1')
  const evIsActive = (tab) => {
    setIsActive(tab)
  }

    //warning
    const errorBody =
        <div className="alert alert-danger"> 
          <ul>
            <li>{formResps.messageShow}</li>
          </ul>
        </div>

    const errorHead = <h5 className="text-danger">Error Alert</h5>

  return (
    <>
      <section className="echron-main echron-addedit">
        <Breadcrumb page="Customer Add" btn={breadBtn} />
        <div className='container-fluid'>
          <table className="echron-head">
            <tbody>
              <tr>
                <th align="left"><i className="fa fa-pencil"></i> Add Customer</th>
              </tr>
            </tbody>
          </table>
          <div className='echron-content'>
            <div className='tab'>
              <ul>
                <li className={isActive === 'tab-1' ? 'active' : ''} onClick={() => evIsActive('tab-1')}>General</li>
                <li className={isActive === 'tab-2' ? 'active' : ''} onClick={() => evIsActive('tab-2')}>Data</li>
                <li className={isActive === 'tab-3' ? 'active' : ''} onClick={() => evIsActive('tab-3')}>Image</li>
              </ul>

              <div className={isActive === 'tab-1' ? 'form active' : 'form'}>
                <div className="group">
                  <label><span><sup>*</sup>Name</span></label>
                  <input className="input" value={formSet.name} type="text" placeholder="Name" onChange={(e)=>{dispatch(echronEvInput({value: e.target.value, type: 'name'}))}} />
                </div>
                <div className="group">
                  <label><span><sup>*</sup>Phone</span></label>
                  <input className="input" value={formSet.phone} type="text" placeholder="Phone" onChange={(e)=>{dispatch(echronEvInput({value: e.target.value, type: 'phone'}))}} />
                </div>
                <div className="group">
                  <label><span>Type</span></label>
                  <select className="input" name="m_follow" value={formSet.type} onChange={(e) => { dispatch(echronEvInput({ value: e.target.value, type: 'type' })); }} >
                    <option value="1">Normal</option>
                    <option value="2">Prime</option>
                    <option value="3">VIP</option>
                  </select>
                </div>
              </div>

              <div className={isActive === 'tab-2' ? 'form active' : 'form'}>
                <div className="group">
                  <label><span>Email</span></label>
                  <input className="input" value={formSet.email} type="email" placeholder="Email" onChange={(e)=>{dispatch(echronEvInput({value: e.target.value, type: 'email'}))}} />
                </div>
                <div className="group">
                  <label><span>Alt Phone</span></label>
                  <input className="input" value={formSet.phone_alt} type="text" placeholder="Alt Phone" onChange={(e)=>{dispatch(echronEvInput({value: e.target.value, type: 'phone_alt'}))}} />
                </div>
                <div className="group">
                  <label><span>Address</span></label>
                  <textarea className="input" value={formSet.address} cols="30" rows="5" onChange={(e)=>{dispatch(echronEvInput({value: e.target.value, type: 'address'}))}}></textarea>
                </div>
              </div>

              <div className={isActive === 'tab-3' ? 'form active' : 'form'}>
                {
                  (formSet.picture_view)?
                  (<img width={200} className="mt-4" src={'https://app.denovatailors.com/storage/customer/'+formSet.picture_view} alt="" />)
                  :
                  (<div className="group">
                    <label><span>Image</span></label>
                    <input className="input" type="file"  onChange={(e)=>{dispatch(echronEvInput({value: e.target.files[0], type: 'picture'}))}} />
                  </div>)
                }
              </div>
            </div>
          </div>
        </div>
        {formResps.error && <Warning body={errorBody} head={errorHead} />}
      </section>  
    </>
  )
}
export default CustomerAdd