//common index component
import { Outlet } from 'react-router-dom'
import Header from '../component/common/Header'
import Navbar from '../component/common/Navbar'
import { useGetDashsQuery } from "../echron/clientapi"
import { useSelector } from 'react-redux'
import { selectAdmLog } from "../echron/formSlice"
function Echrontech() {
  const admLog = useSelector(selectAdmLog)
  const DashRes = useGetDashsQuery(admLog)
  if(DashRes.isLoading) return <div>Loading...</div>
	if(DashRes.isError) {
    if(!DashRes.error.data.auth) return window.location.href = '/adm/login'; else return <h1>An Error occured {DashRes.error.error}</h1>
  }
	if(DashRes.isSuccess) {
    return (
      <>
        <Header />
        <Navbar />
        <Outlet />
        {/* <Footer /> */}
      </>
    )
  }
}
export default Echrontech