import '../../assets/css/Login.css'
import mainLogo from'../../assets/img/logo.png'
import { useDispatch, useSelector } from 'react-redux'
import { useGetDashsQuery } from "../../echron/clientapi"
import { echronEvInput, selectForm, useSubmitLoginForm, selectFormResp } from "../../echron/formSlice"
import Warning from "../../component/Warning"
import { selectAdmLog } from "../../echron/formSlice"


function Login() {
  const dispatch = useDispatch()
  const formSet = useSelector(selectForm)

  //post api
  const submitForm = useSubmitLoginForm();
  const formResps = useSelector(selectFormResp);
  const echronEvSubmit = () => {
    if(!formResps.load) {
      submitForm();
    }
  }

  //warning
  const errorBody =
  <div className="alert alert-danger"> 
    <ul>
      <li>{formResps.messageShow}</li>
    </ul>
  </div>

  const errorHead = <h5 className="text-danger">Error Alert</h5>

  const admLog = useSelector(selectAdmLog)
  const DashRes = useGetDashsQuery(admLog)
  if(DashRes.isLoading) return <div>Loading...</div>
	if(DashRes.isSuccess) return window.location.href = '/adm';
  if(DashRes.isError) {
    return (
      <>
        <section className="echron-login">
          <div className="content">
            
            <form>
              <h4><i className="fa fa-user"></i> Please enter your login details.</h4>
              <figure><img className="logo" src={mainLogo} alt="mainLogo" /></figure>
              <ul>
                <li>
                  <label htmlFor="">Username</label>
                  <input type="text" placeholder="Username" name="username" onChange={(e)=>{dispatch(echronEvInput({value: e.target.value, type: 'username'}))}} />
                </li>
                <li>
                  <label htmlFor="">Password</label>
                  <input type="password" placeholder="Password" name="password" onChange={(e)=>{dispatch(echronEvInput({value: e.target.value, type: 'password'}))}} />
                  <a href="#">Forgotten Password</a>
                </li>
              </ul>
              <div className="echronButton">
                <button type="button"  onClick={()=>echronEvSubmit()} ><i className="fa fa-key"></i> {formResps.load ? 'Please Wait...' : 'Login'} </button>
              </div>
            </form>
          </div>
          <div className="copyrights">
            Design & Develope by <a href="https://echrontech.com" target='_blank'>Echrontech</a> © 
            <small> 2009-2024 All Rights Reserved.</small>
          </div>
          {formResps.error && <Warning body={errorBody} head={errorHead} />}
        </section>
      </>
    )
  }
}
export default Login