import { useGetDashsQuery } from "../echron/clientapi"
import { useSelector } from 'react-redux'
import { selectAdmLog } from "../echron/formSlice"
import {NavLink} from 'react-router-dom'
function HomeMain() {
  const admLog = useSelector(selectAdmLog)
  const custsRes = useGetDashsQuery(admLog)
  //console.log(custsRes)
  if(custsRes.isLoading) return <div>Loading...</div>
	if(custsRes.isError) {
    <h1>An Error occured {custsRes.error.error}</h1>
  }
	if(custsRes.isSuccess) {
    console.log(custsRes)
    return (
      <>
        <section>
          <h1>De Nova Tailors App <NavLink to={"/adm/logout/"} className={"btn btn-danger"}><i className="fa fa-sign-out"></i> Logout</NavLink></h1>
          <table className="echron-head" cellPadding={5}>
            <tbody>
              <tr>
                <th>id</th>
                <th>name</th>
                <th>phone</th>
                <th>emial</th>
              </tr>
              <tr>
                <td>{custsRes.data.result[0].id}</td>
                <td>{custsRes.data.result[0].name}</td>
                <td>{custsRes.data.result[0].phone}</td>
                <td>{custsRes.data.result[0].email}</td>
              </tr>
            </tbody>
          </table>
        </section>
      </>
    )
  }
}
export default HomeMain